import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { FormContainer, RadioButtonGroup } from 'react-hook-form-mui';
import { useListBuilder } from './ListBuilderContext';
import { OrganizationsInputMethod, OrganizationsInputMethods } from './types';
import { CompanyListForm } from './OrganizationsInput/CompanyListForm';
import { AddCompanyForm } from './OrganizationsInput/AddCompanyForm';
import { CSVUploadForm } from './CSVUploadForm';

interface OrganizationsInputMethodFormData {
  method: string;
}

export function OrganizationsInputMethodCard() {
  const onSubmit = (data: OrganizationsInputMethodFormData) => {
    // Handle form submission
    console.log(data);
  };

  const {
    state: { listEntityType, organizationsInputMethod },
    dispatch,
  } = useListBuilder();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'ORGANIZATIONS_INPUT_METHOD_CHANGED',
        organizationsInputMethod: event.target
          .value as OrganizationsInputMethod,
      });
    },
    [dispatch]
  );

  const shouldDisplay = listEntityType === 'organizations';

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Which organizations do you want to track at events?
        </Typography>

        <Stack spacing={2}>
          <RadioGroup
            name="organizationsInputMethod"
            value={organizationsInputMethod}
            onChange={onChange}
          >
            {Object.entries(OrganizationsInputMethods).map(([key, value]) => (
              <FormControlLabel value={key} control={<Radio />} label={value} />
            ))}
          </RadioGroup>
          {organizationsInputMethod === 'manual' ? <AddCompanyForm /> : null}
          {organizationsInputMethod === 'existing' ? <CompanyListForm /> : null}
          {organizationsInputMethod === 'csv' ? <CSVUploadForm /> : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
