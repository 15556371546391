import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

// Define the validation schema using Zod
const schema = z.object({
  companyName: z.string().nonempty('Company name is required'),
});

export function AddPeopleOrganizationForm() {
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: any) => {
    console.log(`Adding company: `, data);
    // Handle adding the company logic here
  };

  const handleClose = () => {
    console.log('Closing AddPeopleOrganizationForm');
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Add new company</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <TextField placeholder="Company name" {...field} fullWidth />
            )}
          />
          <Button variant="contained" startIcon={<AddIcon />} type="submit">
            Add
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
