import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  RadioGroup,
  Typography,
  Stack,
  FormControlLabel,
  Radio,
} from '@mui/material';

import { useListBuilder } from './ListBuilderContext';
import { PeopleJobTitleForm } from './PeopleInput/PeopleJobTitleForm';
import { PeopleInputMethod, PeopleInputMethods } from './types';
import { CSVUploadForm } from './CSVUploadForm';
import { AddPersonForm } from './PeopleInput/AddPersonForm';

export function PeopleInputMethodCard() {
  const {
    state: { listEntityType, peopleInputMethod },
    dispatch,
  } = useListBuilder();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'PEOPLE_INPUT_METHOD_CHANGED',
        peopleInputMethod: event.target.value as PeopleInputMethod,
      });
    },
    [dispatch]
  );

  const shouldDisplay = listEntityType === 'profiles';

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Who do you want to track at events?
        </Typography>

        <Stack spacing={2}>
          <RadioGroup
            name="peopleInputMethod"
            value={peopleInputMethod}
            onChange={onChange}
          >
            {Object.entries(PeopleInputMethods).map(([key, value]) => (
              <FormControlLabel value={key} control={<Radio />} label={value} />
            ))}
          </RadioGroup>

          {peopleInputMethod === 'manual' ? <AddPersonForm /> : null}
          {peopleInputMethod === 'csv' ? <CSVUploadForm /> : null}
          {peopleInputMethod === 'jobTitles' ? <PeopleJobTitleForm /> : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
