import { Card, CardContent } from '@mui/material';
import { useListBuilder } from './ListBuilderContext';

export function PreviewCard() {
  const {
    state: { apiParams },
  } = useListBuilder();
  return (
    <Card>
      <CardContent>
        <h2>Preview of List</h2>
        <code>{JSON.stringify(apiParams)}</code>
      </CardContent>
    </Card>
  );
}
