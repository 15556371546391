export const ListTypes = {
  customers: 'Customers',
  competitors: 'Competitors',
  crm_deals: 'CRM Deals',
  prospects: 'Prospects',
};

export type ListType = keyof typeof ListTypes;

export const ListEntityTypes = {
  profiles: 'People',
  organizations: 'Organizations',
};

export type ListEntityType = keyof typeof ListEntityTypes;

export const listTypeOptions = Object.entries(ListTypes).map(
  ([key, value]) => ({
    id: key,
    label: value,
  })
);

export const OrganizationsInputMethods = {
  manual: 'Enter manually',
  csv: 'Upload CSV',
  existing: 'Use existing company list',
};

export type OrganizationsInputMethod = keyof typeof OrganizationsInputMethods;

export const PeopleInputMethods = {
  manual: 'Enter manually',
  csv: 'Upload CSV',
  jobTitles: 'Define personas',
};

export type PeopleInputMethod = keyof typeof PeopleInputMethods;

export const PeopleOrganizationsInputMethods = {
  list: 'Use existing company list',
  describe: 'Company type',
  crm: 'Import from CRM',
  manual: 'Enter manually',
  csv: 'Upload CSV',
};

export type PeopleOrganizationsInputMethod =
  keyof typeof PeopleOrganizationsInputMethods;
