import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
} from 'react';
import {
  ListType,
  OrganizationsInputMethod,
  PeopleInputMethod,
  PeopleOrganizationsInputMethod,
} from './types';
import { ListEntityType } from './types';
import { ListWizardWizardParams } from 'components/Wizard/WizardConfig';
import { AddPersonFormSchema } from './PeopleInput/AddPersonForm';

// Define the state structure
interface ListBuilderState {
  listType: ListType;
  listName: string | undefined;
  listEntityType: ListEntityType;
  organizationsInputMethod?: OrganizationsInputMethod;
  peopleInputMethod?: PeopleInputMethod;
  peopleOrganizationsInputMethod?: PeopleOrganizationsInputMethod;
  saveListRequested: boolean;
  addPersonRequested: boolean;
  apiParams: Partial<ListWizardWizardParams>;
}

// Define possible actions
type ListBuilderAction =
  | { type: 'LIST_TYPE_CHANGED'; listType: ListType }
  | { type: 'LIST_NAME_CHANGED'; listName: string | undefined }
  | { type: 'LIST_ENTITY_TYPE_CHANGED'; listEntityType: ListEntityType }
  | {
      type: 'ORGANIZATIONS_INPUT_METHOD_CHANGED';
      organizationsInputMethod: OrganizationsInputMethod;
    }
  | {
      type: 'PEOPLE_INPUT_METHOD_CHANGED';
      peopleInputMethod?: PeopleInputMethod;
    }
  | {
      type: 'PEOPLE_ORGANIZATIONS_INPUT_METHOD_CHANGED';
      peopleOrganizationsInputMethod?: PeopleOrganizationsInputMethod;
    }
  | { type: 'SAVE_LIST_REQUESTED' }
  | { type: 'CLEAR_SAVE_LIST_REQUEST' }
  | { type: 'ADD_PERSON_REQUESTED'; person: AddPersonFormSchema };
// Create the initial state
const initialState: ListBuilderState = {
  listType: 'customers',
  listName: '',
  listEntityType: 'profiles',
  organizationsInputMethod: undefined,
  peopleInputMethod: undefined,
  peopleOrganizationsInputMethod: undefined,
  saveListRequested: false,
  addPersonRequested: false,
  apiParams: {},
};

// Create the reducer
function listBuilderReducer(
  state: ListBuilderState,
  action: ListBuilderAction
): ListBuilderState {
  console.log(`listBuilderReducer: `, action.type, action);

  switch (action.type) {
    case 'LIST_TYPE_CHANGED': {
      return {
        ...state,
        listType: action.listType,
      };
    }
    case 'LIST_NAME_CHANGED': {
      return {
        ...state,
        listName: action.listName,
      };
    }

    case 'LIST_ENTITY_TYPE_CHANGED': {
      return {
        ...state,
        listEntityType: action.listEntityType,
      };
    }

    case 'ORGANIZATIONS_INPUT_METHOD_CHANGED': {
      return {
        ...state,
        organizationsInputMethod: action.organizationsInputMethod,
      };
    }

    case 'PEOPLE_INPUT_METHOD_CHANGED': {
      return {
        ...state,
        peopleInputMethod: action.peopleInputMethod,
      };
    }

    case 'PEOPLE_ORGANIZATIONS_INPUT_METHOD_CHANGED': {
      return {
        ...state,
        peopleOrganizationsInputMethod: action.peopleOrganizationsInputMethod,
      };
    }

    case 'SAVE_LIST_REQUESTED': {
      return {
        ...state,
        saveListRequested: true,
      };
    }

    case 'CLEAR_SAVE_LIST_REQUEST': {
      return {
        ...state,
        saveListRequested: false,
      };
    }

    default:
      return state;
  }
}

// Create the context
const ListBuilderContext = createContext<{
  state: ListBuilderState;
  dispatch: React.Dispatch<ListBuilderAction>;
} | null>(null);

// Create the provider component
interface ListBuilderProviderProps {
  children: ReactNode;
}

export function ListBuilderProvider({ children }: ListBuilderProviderProps) {
  const [state, dispatch] = useReducer(listBuilderReducer, initialState);

  useEffect(() => {
    if (state.saveListRequested === true) {
      dispatch({ type: 'CLEAR_SAVE_LIST_REQUEST' });
      console.log(`---------------- SAVING LIST `);
    }
  }, [dispatch, state.saveListRequested]);

  useEffect(() => {
    if (state.addPersonRequested === true) {
      console.log(`---------------- ADDING PERSON `);
    }
  }, [dispatch, state.addPersonRequested]);

  return (
    <ListBuilderContext.Provider value={{ state, dispatch }}>
      {children}
    </ListBuilderContext.Provider>
  );
}

// Create a custom hook for using the context
export function useListBuilder() {
  const context = useContext(ListBuilderContext);
  if (!context) {
    throw new Error('useListBuilder must be used within a ListBuilderProvider');
  }
  return context;
}
