import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';

export function CompanyTypeForm() {
  return (
    <Stack spacing={2}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="industry-select-label">Industry</InputLabel>
        <Select
          labelId="industry-select-label"
          // value={industryValue}
          // onChange={handleIndustryChange}
          // label="Industry"
        >
          {/* {industries.map((industry) => (
            <MenuItem key={industry} value={industry}>
              {industry}
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel id="company-size-select-label">Company Size</InputLabel>
        <Select
          labelId="company-size-select-label"
          // value={companySizeValue}
          // onChange={handleCompanySizeChange}
          // label="Company Size"
        >
          {/* {companySizes.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
    </Stack>
  );
}
