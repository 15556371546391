import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export function ExistingListForm() {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="existing-list-select-label">
        Select Existing Company List
      </InputLabel>
      <Select
        labelId="existing-list-select-label"
        // value={value}
        // onChange={onChange}
        // label={label}
      >
        {/* {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))} */}
      </Select>
    </FormControl>
  );
}
