import React, { SyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, Button } from '@mui/material';
// import { FileInput } from 'react-hook-form-mui';

export function CSVUploadForm() {
  const form = useForm();

  // const handleFileUpload = (file: File) => {
  //   onSubmit(file);
  // };

  return (
    <Box component="form" onSubmit={form.handleSubmit(() => {})}>
      <Typography variant="h6" gutterBottom>
        Upload CSV
      </Typography>

      <input
        type="file"
        name="file"
        // control={form.control}
        // label="Choose CSV File"
        accept=".csv"
        onChange={(event: SyntheticEvent) => {
          // const file = event.target.files?.[0];
          // if (file) {
          //   handleFileUpload(file);
          // }
        }}
      />

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        onClick={form.handleSubmit(() => {})}
      >
        Submit
      </Button>
    </Box>
  );
}
