import { Card, CardContent, Typography, Tabs, Tab, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useListBuilder } from './ListBuilderContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`list-content-tabpanel-${index}`}
      aria-labelledby={`list-content-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export function ListContentsCard() {
  const {
    state: { listEntityType },
    dispatch,
  } = useListBuilder();
  const tabValue = listEntityType === 'profiles' ? 0 : 1;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({
      type: 'LIST_ENTITY_TYPE_CHANGED',
      // @ts-expect-error I'm guessing one of our TS DOM libs is out of date. dataset definitely exists.
      listEntityType: event.currentTarget.dataset.value,
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          List Content
        </Typography>

        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="People" sx={{ fontSize: '1rem' }} data-value="profiles" />
          <Tab
            label="Organizations"
            sx={{ fontSize: '1rem' }}
            data-value="organizations"
          />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Typography variant="body1" gutterBottom>
            Build People Lists to:
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
            }}
          >
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Discover new events with the people you care about
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Evaluate who is attending events
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Get alerts when key people are added to events
            </ListItem>
          </List>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Typography variant="body1" gutterBottom>
            Build Organization Lists to:
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
            }}
          >
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Discover new events with the companies you care about
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Evaluate companies that are sponsoring events
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Get alerts when target accounts are added to events
            </ListItem>
          </List>
        </TabPanel>
      </CardContent>
    </Card>
  );
}
