import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export function PeopleJobTitleForm() {
  const [jobTitle, setJobTitle] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (jobTitle) {
      // onAddJobTitle(jobTitle);
      setJobTitle(''); // Reset the input field
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Define Personas (Job Titles)
      </Typography>

      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          fullWidth
          label="Enter job title"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" startIcon={<AddIcon />}>
          Add
        </Button>
      </Stack>
    </Box>
  );
}
