import { Grid, Stack } from '@mui/material';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import { ListBuilderProvider } from 'components/ListBuilder4/ListBuilderContext';
import { ListDetailsCard } from 'components/ListBuilder4/ListDetailsCard';
import { ListContentsCard } from 'components/ListBuilder4/ListContentsCard';
import { PeopleInputMethodCard } from 'components/ListBuilder4/PeopleInputMethodCard';
import { PeopleOrganizationsInputMethodCard } from 'components/ListBuilder4/PeopleOrganizationsInputMethodCard';
import { MuiIconManifest } from 'utils/iconManifest';
import { OrganizationsInputMethodCard } from 'components/ListBuilder4/OrganizationsInputMethodCard';
import { Preview } from '@mui/icons-material';
import { PreviewCard } from 'components/ListBuilder4/PreviewCard';
import { SaveButton } from 'components/ListBuilder4/SaveButton';

export function _NewListRoute() {
  return (
    <ListBuilderProvider>
      <SectionLayout
        title={'New list'}
        rightsideToolbar={<SaveButton />}
        slotProps={{
          container: {
            maxWidth: 'lg',
          },
        }}
      >
        <Stack spacing={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <ListDetailsCard />

              <ListContentsCard />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <OrganizationsInputMethodCard />

              <PeopleInputMethodCard />

              <PeopleOrganizationsInputMethodCard />
            </Grid>

            <Grid item xs={12} md={4} lg={5}>
              <PreviewCard />
            </Grid>
          </Grid>
        </Stack>
      </SectionLayout>
    </ListBuilderProvider>
  );
}

export default withAppAuthenticationRequired(_NewListRoute);
