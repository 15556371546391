import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useListBuilder } from './ListBuilderContext';
import {
  PeopleOrganizationsInputMethod,
  PeopleOrganizationsInputMethods,
} from './types';
import { ExistingListForm } from './PeopleOrganizationsInput/ExistingListForm';
import { CRMFieldsForm } from './PeopleOrganizationsInput/CRMFieldsForm';
import { CompanyTypeForm } from './PeopleOrganizationsInput/CompanyTypeForm';
import { AddPeopleOrganizationForm } from './PeopleOrganizationsInput/AddPeopleOrganizationForm';
import { CSVUploadForm } from './CSVUploadForm';

interface PeopleOrganizationsInputMethodFormData {
  method: string;
}

export function PeopleOrganizationsInputMethodCard() {
  const {
    state: { listEntityType, peopleOrganizationsInputMethod },
    dispatch,
  } = useListBuilder();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'PEOPLE_ORGANIZATIONS_INPUT_METHOD_CHANGED',
        peopleOrganizationsInputMethod: event.target
          .value as PeopleOrganizationsInputMethod,
      });
    },
    [dispatch]
  );

  const shouldDisplay = listEntityType === 'profiles';

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Which organizations do these people work at?
        </Typography>

        <Stack spacing={2}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="peopleOrganizationsInputMethod"
            value={peopleOrganizationsInputMethod}
            onChange={onChange}
          >
            {Object.entries(PeopleOrganizationsInputMethods).map(
              ([key, value]) => (
                <FormControlLabel
                  value={key}
                  control={<Radio />}
                  label={value}
                />
              )
            )}
          </RadioGroup>

          {peopleOrganizationsInputMethod === 'list' ? (
            <ExistingListForm />
          ) : null}

          {peopleOrganizationsInputMethod === 'describe' ? (
            <CompanyTypeForm />
          ) : null}

          {peopleOrganizationsInputMethod === 'crm' ? <CRMFieldsForm /> : null}

          {peopleOrganizationsInputMethod === 'manual' ? (
            <AddPeopleOrganizationForm />
          ) : null}

          {peopleOrganizationsInputMethod === 'csv' ? <CSVUploadForm /> : null}

          {/*

          {peopleOrganizationsInputMethod === 'jobTitles' ? (
            <PeopleJobTitleForm />
          ) : null} */}
        </Stack>
      </CardContent>
    </Card>
  );
}
