import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';

export function CRMFieldsForm() {
  return (
    <Stack spacing={2}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="deal-stage-select-label">Deal Stage</InputLabel>
        <Select
          labelId="deal-stage-select-label"
          // value={dealStageValue}
          // onChange={handleDealStageChange}
          // label="Deal Stage"
        >
          {/* {dealStages.map((stage) => (
            <MenuItem key={stage} value={stage}>
              {stage}
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel id="deal-owner-select-label">Deal Owner</InputLabel>
        <Select
          labelId="deal-owner-select-label"
          // value={dealOwnerValue}
          // onChange={handleDealOwnerChange}
          // label="Deal Owner"
        >
          {/* {dealOwners.map((owner) => (
            <MenuItem key={owner} value={owner}>
              {owner}
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
    </Stack>
  );
}
