import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

export function AddCompanyForm() {
  const [companyName, setCompanyName] = useState('');

  const handleAdd = () => {
    // Handle adding the company
    console.log(`Adding company: ${companyName}`);
    setCompanyName(''); // Reset the input field
  };

  const handleClose = () => {
    // Handle closing the form
    console.log('Closing AddCompanyForm');
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Add new company</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
        <TextField
          placeholder="Company name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          fullWidth
        />
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAdd}>
          Add
        </Button>
      </Stack>
    </Box>
  );
}
