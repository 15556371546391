import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormError } from '../FormError';
import { useListBuilder } from '../ListBuilderContext';
import CloseIcon from '@mui/icons-material/Close';

export const addPersonFormSchema = z.object({
  name: z.string(),
  jobTitle: z.string(),
  company: z.string(),
});

const defaultValues = {
  name: '',
  jobTitle: '',
  company: '',
};

export type AddPersonFormSchema = z.infer<typeof addPersonFormSchema>;

export function AddPersonForm() {
  const { state, dispatch } = useListBuilder();

  const onSubmit = (values: typeof defaultValues) => {
    console.log(`---------------- onSubmit `, values);
    dispatch({
      type: 'ADD_PERSON_REQUESTED',
      person: values,
    });
  };

  const form = useForm<AddPersonFormSchema>({
    resolver: zodResolver(addPersonFormSchema),
    defaultValues,
  });

  const handleClose = () => {
    // Handle closing the form
    console.log('Closing AddPersonForm');
  };

  console.log(`---------------- form.formState.errors `, form.formState.errors);

  return (
    <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Add new person</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Stack spacing={2}>
        <Controller
          control={form.control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              placeholder="Name"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          name="name"
        />

        {form.formState.errors.name && (
          <FormError message="This is required." />
        )}

        <Controller
          control={form.control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              placeholder="Job title"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          name="jobTitle"
        />

        {form.formState.errors.jobTitle && (
          <FormError message="This is required." />
        )}

        <Controller
          control={form.control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              placeholder="Company name"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          name="company"
        />

        {form.formState.errors.company && (
          <FormError message="This is required." />
        )}

        <Button
          type="submit"
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
        >
          Add person
        </Button>
      </Stack>
    </Box>
  );
}
