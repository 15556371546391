import React, { useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
  useForm,
} from 'react-hook-form-mui';
import { useListBuilder } from './ListBuilderContext';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ListType, listTypeOptions, ListTypes } from './types';

interface ListDetailsFormData {
  listName: string;
  listType: string;
}

export const listDetailsFormSchema = z.object({
  listName: z.string(),
  listType: z.enum(
    Object.keys(ListTypes) as [
      keyof typeof ListTypes,
      ...Array<keyof typeof ListTypes>
    ]
  ),
});

type ListDetailsFormSchema = z.infer<typeof listDetailsFormSchema>;

export function ListDetailsCard() {
  const {
    state: { listName, listType },
    dispatch,
  } = useListBuilder();

  const form = useForm<ListDetailsFormSchema>({
    defaultValues: {
      listName,
      listType,
    },
    resolver: zodResolver(listDetailsFormSchema),
  });
  const { register, handleSubmit, setValue, watch } = form;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case 'listName': {
          dispatch({
            type: 'LIST_NAME_CHANGED',
            listName: value.listName,
          });
          break;
        }
        case 'listType': {
          dispatch({
            type: 'LIST_TYPE_CHANGED',
            listType: value.listType as ListType,
          });
          break;
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  const onSubmit = (data: ListDetailsFormData) => {
    // Handle form submission
    console.log(data);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          List Details
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <TextFieldElement
            name="listName"
            label="List Name"
            required
            fullWidth
            margin="normal"
            control={form.control}
          />

          <SelectElement
            name="listType"
            label="List Type"
            options={listTypeOptions}
            required
            fullWidth
            margin="normal"
            control={form.control}
          />
        </form>
      </CardContent>
    </Card>
  );
}
