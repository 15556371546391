import { Button } from '@mui/material';
import { useCallback } from 'react';
import { MuiIconManifest } from 'utils/iconManifest';
import { useListBuilder } from './ListBuilderContext';

export function SaveButton() {
  const { dispatch } = useListBuilder();

  const onClick = useCallback(() => {
    dispatch({ type: 'SAVE_LIST_REQUESTED' });
  }, [dispatch]);

  return (
    <Button
      variant="contained"
      startIcon={<MuiIconManifest.SaveIcon />}
      onClick={onClick}
    >
      Save list
    </Button>
  );
}
