import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Define the validation schema using Zod
const schema = z.object({
  company: z.string().nonempty('Company is required'),
});

export function CompanyListForm() {
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(schema),
  });

  // const onSubmit = (data) => {
  //   console.log(data);
  //   // Handle form submission
  // };

  const onClose = () => {};

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Select existing company list
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="existing-company-list-select-label">
          Existing Company List
        </InputLabel>
        <Controller
          name="company"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select labelId="existing-company-list-select-label" {...field}>
              <MenuItem value="company1">Company 1</MenuItem>
              <MenuItem value="company2">Company 2</MenuItem>
              <MenuItem value="company3">Company 3</MenuItem>
            </Select>
          )}
        />
      </FormControl>
    </Stack>
  );
}
